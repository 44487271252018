import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

import './styles.css';

import Header from "./components/Header";
import ProgressBar from './components/ProgressBar';
import Home from "./routes/home";
import Demographics from "./routes/demographics";
import Headphones from "./routes/headphones";
import Environment from "./routes/environment"
import Device from "./routes/device";
import Instructions from "./routes/instructions";
// import Slider from "./routes/slider";
import Test from "./routes/test";
import Results from "./routes/results";
import DetailedResults from "./routes/detailedResults";

// import useTest from "./hooks/useTest";
import { UserInfoProvider } from './context/UserInfoContext';

import Analytics from 'analytics'
import segmentPlugin from '@analytics/segment'

function App() {
  const [currentStep, setCurrentStep] = useState(0);
  const [progress, setProgress] = useState(0);
  const [nextEarCalled, setNextEarCalled] = useState(false);
  const [finishTrainingCalled, setFinishedTrainingCalled] = useState(false);
  const [startTrainingCalled, setStartTrainingCalled] = useState(false);

  // key for hiding buttons when user is linked from partner site
  const queryParams = new URLSearchParams(window.location.search);
  const [isB2b2c] = useState(!!(queryParams.get('utm_source') === 'b2b2c'));

  const analytics = Analytics({
    app: 'jht-web-app',
    plugins: [
      segmentPlugin({
        writeKey: 'hfVA7JIAz6vAqCfkExdcCAUUyhQUQliK'
      })
    ]
  });

  const trackEvent = (eventName, email) => {
    analytics.track(
      eventName,
      { email: email }
    );
  }

  const identifyUser = (userId, email, firstName, lastName) => {
    analytics.identify(
      userId,
      {
        email: email,
        firstName: firstName,
        lastName: lastName
      }
    );
  }

  return (
    <main className="top-container">
      <UserInfoProvider>
        <Router>
          <Header />
          <ProgressBar 
            currentStep={currentStep}
            progress={progress}
          />

          <Routes>

            <Route 
              path="/" 
              element={<Home trackEvent={trackEvent} />} />
            <Route 
              path="/demographics" 
              element={
                <Demographics 
                  trackEvent={trackEvent}
                  identifyUser={identifyUser}
                  setProgress={() => setProgress(20)} 
                />
              } 
            />
            <Route 
              path="/device" 
              element={
                <Device 
                  setProgress={() => setProgress(40)} 
                />
              } 
            />
            <Route 
              path="/environment" 
              element={
                <Environment 
                  trackEvent={trackEvent} 
                  setProgress={() => setProgress(60)} 
                />
              } 
            />
            <Route 
              path="/headphones" 
              element={
                <Headphones 
                  trackEvent={trackEvent} 
                  setProgress={() => setProgress(80)} 
                />
              } 
            />
            <Route 
              path="/instructions" 
              element={
                <Instructions 
                  trackEvent={trackEvent}
                  setProgress={() => setProgress(100)}
                  nextStep={() => {
                    setProgress(5);
                    setCurrentStep(1);
                  }}
                />} 
              />
            {/* <Route 
              path="/slider"
              element={<Slider />} 
            /> */}
            <Route 
              path="/test" 
              element={
                <Test 
                  setProgress={() => setProgress(progress + 16.66666667)}
                  startTraining={() => {
                    setProgress(15);
                    setCurrentStep(1);
                    setStartTrainingCalled(true);
                  }}
                  finishTraining={() => {
                    setProgress(0);
                    setCurrentStep(2);
                    setFinishedTrainingCalled(true);
                  }}
                  nextEar={() => {
                    setProgress(0);
                    setCurrentStep(3);
                    setNextEarCalled(true);
                  }}
                  nextStep={() => {
                    setProgress(90);
                    setCurrentStep(4);
                  }}
                  trackEvent={trackEvent}
                  nextEarCalled={nextEarCalled}
                  finishTrainingCalled={finishTrainingCalled}
                  startTrainingCalled={startTrainingCalled}
                  isB2b2c={isB2b2c}
                />
              } 
            />
            <Route 
              path="/results" 
              element={<Results trackEvent={trackEvent} isB2b2c={isB2b2c} />} 
            />
            <Route 
              path="/results/detailedresults"
              element={<DetailedResults trackEvent={trackEvent} />}
            />
          </Routes>
        </Router>
      </UserInfoProvider>  
    </main>
  );
}

export default App;
