import soundwaves from '../assets/images/soundwaves.png';
import AudioPlayer from "../components/AudioPlayer";
import React, { useEffect, useRef, useState } from 'react';
import constants from '../constants'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import useTest from '../hooks/useTest';
import { useUserInfo } from '../context/UserInfoContext';

const audioDuration = 1738 // in ms

const indexMap = {
  "500": "500 Hz",
  "1000": "1000 Hz",
  "2000": "2000 Hz",
  "3000": "3000 Hz",
  "4000": "4000 Hz",
  "8000": "8000 Hz"
};

const Test = ({
  setProgress,
  nextStep,
  nextEar,
  nextEarCalled,
  startTraining,
  finishTraining,
  finishTrainingCalled,
  trackEvent,
  isB2b2c,
}) => {

  const {
    testData,
    updateTestData,
    resetTestData,
    loudness,
    setLoudness,
    frequency,
    setFrequency,
    isTraining,
    checkStoppingCriteria,
    handleEndTest,
    dataPosted,
    setDataPosted,
    freqList
  } = useTest({
    trackEvent, 
    nextStep, 
    nextEar, 
    nextEarCalled,
    startTraining,
    finishTraining,
    finishTrainingCalled,
    isB2b2c
  });

  const isMobile = window.innerWidth <= 620;
  const navigate = useNavigate();

  const [hasPressedHeardOnce, setHasPressedHeardOnce] = useState(false);
  const [heardPressed, setHeardPressed] = useState(false);
  const [heardAnimation, setHeardAnimation] = useState(false);

  // value is time since audio began to button click
  const [heardInterval, setHeardInterval] = useState(0);
  const [timer, setTimer] = useState(0);
  const [trialIndex, setTrialIndex] = useState(0);
  const [trialRunning, setTrialRunning] = useState(false);

  const [testStarted, setTestStarted] = useState(false);
  const [testEnded, setTestEnded] = useState(false);

  const [backEventFinished, setBackEventFinished] = useState(false);

  // refs to access current state values inside trial timeout function
  const heardRef = useRef(null);
  const heardIntervalRef = useRef(null);
  const hasPressedHeardOnceRef = useRef(null);

  const { userInfo } = useUserInfo();

  // handle back button and refresh events
  useEffect(() => {
    if (!userInfo.contactid) {
      return navigate('/demographics');
    }

    // show popup when user tries to reload page during test
    const onRefreshEvent = (e) => {
      e.preventDefault();
      e.returnValue = '';
      return '';
    }

    // show popup when user tries to press back button during test
    const onBackButtonEvent = (e) => {
      e.preventDefault();
      if (!backEventFinished) {
        if (window.confirm('Do you want to go back? If you go back, you\'ll have to restart the test.')) {
          setBackEventFinished(true);
          navigate('/demographics');
        } else {
          window.history.pushState(null, null, window.location.pathname);
          setBackEventFinished(false);
        }
      }
    };
    window.history.pushState(null, null, window.location.pathname);

    window.addEventListener('beforeunload', onRefreshEvent);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('beforeunload', onRefreshEvent);
      window.removeEventListener('popstate', onBackButtonEvent);
    }
  }, [backEventFinished, navigate, userInfo]);

  // generates a random, uniform integer on the interval [lower, upper]
  const generateRandomDelay = () => {
    // Generate a random number between 0 and 1
    const randomFraction = Math.random();

    // Scale and shift the random number to the [intervalLower, intervalUpper] region
    const randomNumber = constants['intervalLower'] + 
      randomFraction * (
        constants['intervalUpper'] - constants['intervalLower']
      );

    // return to the nearest millisecond
    return Math.round(randomNumber);
  }

  // creates & recreates test interval until max trial index is reached
  useEffect(() => {
    // set heardPressed and heardInterval to ref so 
    // they are accessible inside async timeout function
    heardRef.current = heardPressed;
    heardIntervalRef.current = heardInterval;
    hasPressedHeardOnceRef.current = hasPressedHeardOnce;

    if (testStarted && !trialRunning && !testEnded) {
      setFrequency(freqList[0]);

      // initialize random delay between tones
      let randDelay = 0;
      if (!hasPressedHeardOnceRef.current) {
        // just do 2 sec between tones to climb up until 'Heard' response is recorded
        randDelay = 2000;
      } else {
        // they have pressed 'Heard' so generate a random delay
        randDelay = generateRandomDelay();
      }
      // add the audio duration to define the entire response interval
      const audioInterval = randDelay + audioDuration;
      
      // trialRunning avoids multiple timeouts at same time
      setTrialRunning(true);

      // create timeout with random interval
      setTimeout(() => {
        if (!heardRef.current && loudness <= 80) {

          if (!hasPressedHeardOnceRef.current){
            if (loudness === 80) {
              setLoudness(loudness + 5);
            } else {
              // go up in 20 dB increments until first 'Heard' is recorded
              setLoudness(loudness + 20);
            }
          } else {
            // if heard isn't pressed, go to louder sound (+5db)
            setLoudness(loudness + 5);
          }
        } else if (heardRef.current && loudness >= 10) {
          // if heard is pressed, go to quieter sound (-10db)
          setLoudness(loudness - 10);
          setHeardPressed(false);
        }

        const updateData = {
          trialIndex,
          loudness,
          frequency,
          interval: audioInterval, // store the full interval including stimulus duration
          responseTime: heardIntervalRef.current || NaN,
          didUserRespond: heardRef.current ? true : false
        }

        // ensure checkStoppingCriteria gets the latest data
        if (checkStoppingCriteria([...testData, updateData])) {
          setTestEnded(true);
        }
    
        // record test data
        updateTestData(updateData);
        setTrialIndex(trialIndex + 1);
        setTrialRunning(false);
        setTimer(0);
        setHeardInterval(0);
        setHeardPressed(false);

      }, audioInterval);
    } 

    if (testEnded && !dataPosted) {
      handleEndTest();
    }

  }, [
    testStarted, 
    trialRunning, 
    heardPressed, 
    heardInterval,
    loudness,
    frequency,
    trialIndex,
    updateTestData,
    testData,
    resetTestData,
    handleEndTest,
    checkStoppingCriteria,
    dataPosted,
    testEnded,
    hasPressedHeardOnce,
    setFrequency,
    setLoudness,
    freqList
  ]);

  // handle timer for recording how quickly user presses heard button
  useEffect(() => {
    let interval = null;
    if (trialRunning) {
      interval = setInterval(() => {
        setTimer((time) => time + 10);
      }, 10);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [trialRunning]);
  
  const handleClickStartTest = () => {
    setTrialIndex(0);
    // use starting loudness of 45
    setLoudness(45);
    setHasPressedHeardOnce(false);
    setFrequency(freqList[0]);
    setDataPosted(false);
    setTestEnded(false);
    setTestStarted(false);
    // only increment the progress bar if training is done
    if (freqList.length < 12) {
      setProgress();
    }
  }

  const handleClickHeard = () => {
    // if heard is pressed under 100ms after audio begins, do not record
    if (timer >= 100) {
      setHasPressedHeardOnce(true);
      setHeardInterval(timer);
      setHeardPressed(true);
      setHeardAnimation(true);
      setTimeout(() => setHeardAnimation(false), 1000);
    }
  }

  return (
    <div className="test-container">
      <div className="top-title-text-container">
        {!dataPosted && !trialRunning ? (
          <h1 className="top-title-text">
            We are going to play a series of tones at different volumes in your <strong>  
            {frequency[0] === 'R' ? ' right' : ' left'} ear.{' '} </strong>
            Click 'Heard' when you hear the tone.{!isMobile && <br/>} If you hear nothing, just wait for the next tone.
          </h1>
        ) :  !dataPosted ? (
          <h1 className="top-title-text">
            Click 'Heard' when you hear the tone in 
            your <strong>{frequency[0] === 'R' ? ' right' : ' left'} ear.</strong>{' '}
            {!isMobile && <br/>} 
            If you are unsure, it is better to wait.
          </h1>
        ) : (
          freqList.length < 12 ? (
            <h1 className="top-title-text">
              Great job! Click 'Next Frequency' to continue.{!isMobile && <br/>} The test 
              may feel repetitive, but that's to ensure we get a complete
              picture of your hearing profile.
            </h1>
          ) : (
            <h1 className="top-title-text">
            Great job! Now let's start the test. <br/> First, we will
            test your right ear at 6 different frequencies.{!isMobile && <br/>} 
            Then, we will repeat those tests on your left ear.
          </h1>
          )
        )}
      </div>

      <div 
        className={!isTraining ? "left-right-container" : "left-right-container2"}
        style={{
          display: 'flex',
          justifyContent: frequency[0] === 'R' ? 'flex-end' : 'flex-start',
          maxWidth: '1000px',
          margin: '0 auto',
          backgroundColor: '#FFF'
        }}
      >
        <p>
          {frequency[0] === 'R' ? 'Right Ear - ' : 'Left Ear - '} 
          {!isTraining ? indexMap[frequency.substring(1)] : 'Training'}
        </p>
      </div>

      <div className="main-content">
        {dataPosted ? (
          <Link
            className="link-button"
            to='/test'
            onClick={() => handleClickStartTest()}
          >
            {freqList.length < 12 ? "Next Frequency" : "Start Test"}
          </Link>
        ) : testStarted ? (
          <>
            {/* <div className='frequency-container'>
              <p>current frequency: {frequency} hz</p>
              <p>current loudness: {loudness} dB</p>
            </div> */}
            <p className="testing-text">
              Testing your {frequency[0] === 'R' ? 'right' : 'left'} ear
            </p>
            <button 
              className={`
                link-button
                heard-button
                ${heardAnimation ? 'heard-animation' : ''}
              `}
              disabled={heardAnimation}
              onClick={() => handleClickHeard()}
            >
              <div />
              Heard
            </button>
            <p className="testing-footer-text">
              It is normal to hear nothing for several seconds
            </p>
          </>
        ) : (
          <>
          <button 
            className="link-button"
            onClick={() => setTestStarted(true)}
          >
            Begin
          </button>
          <p className="testing-footer-text">
            You may hear nothing on the next screen. <br/>
            It will advance automatically.
          </p>
          </>
        )}
        <img 
          className="soundwaves"
          src={soundwaves}
          alt="soundwaves background"
        />
      </div>
      {!dataPosted &&
        <AudioPlayer 
          loudness={loudness} 
          frequency={frequency}
          autoPlay={trialRunning}
          isTestScreen
        />
      }
    </div>
  );
}

export default Test;